import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { variantFilled, variantOutlined } from '../../focusShim';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys);
const pill = definePartsStyle(props => {
  const {
    field
  } = props.theme.components.Select.variants.filled(props);
  return {
    field: Object.assign({}, field, {
      borderRadius: 'full',
      fontWeight: 'semibold',
      _focus: variantFilled()
    })
  };
});
const outline = definePartsStyle(() => {
  return {
    field: Object.assign({
      fontSize: 'sm'
    }, variantOutlined(), {
      backgroundColor: 'GREYSCALE.SUPERNOVA'
    }),
    icon: {
      color: 'CARNABY.regular'
    }
  };
});
export const selectTheme = defineMultiStyleConfig({
  variants: {
    pill,
    outline
  }
});