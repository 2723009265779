import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { createTextFieldBorderColors } from '../../constants';
import { variantFilled, variantOutlined } from '../../focusShim';
const baseStyle = defineStyle(() => {
  return Object.assign({}, createTextFieldBorderColors(), {
    borderRadius: '30px'
  }, variantOutlined(), {
    border: '1px',
    _focus: Object.assign({
      backgroundColor: 'GREYSCALE.SUPERNOVA'
    }, variantOutlined())
  });
});
const pill = defineStyle(Object.assign({
  border: '1px',
  borderRadius: '20px'
}, variantOutlined()));
const filled = defineStyle({
  border: 'none',
  _focus: Object.assign({
    backgroundColor: 'GREYSCALE.SUPERNOVA'
  }, variantFilled()),
  _invalid: {
    border: '1px solid',
    borderColor: 'KYOTO.regular !important'
  }
});
export const textareaTheme = defineStyleConfig({
  baseStyle,
  variants: {
    pill,
    filled
  }
});