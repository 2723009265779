// @ts-expect-error FIXME
import backgroundLogo from 'bender-url!../../../images/connect-logo-bg-homepage-header.svg';
export const backgroundLinearGradientImageProps = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top',
  backgroundSize: '105%',
  style: {
    backgroundImage: `url('${backgroundLogo}'), linear-gradient(106.61deg, #F8E1EC 0%, #FDF3F3 100%)`
  }
};
export const baseLayoutContainerProps = {
  width: '100%',
  maxWidth: 'none',
  flex: 1,
  padding: 6
};