import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
const baseStyle = defineStyle(() => ({
  borderRadius: 'full',
  _disabled: {
    color: 'GREYSCALE.NEBULA',
    opacity: '1',
    background: 'GREYSCALE.CONSTELLATION',
    _hover: {
      _disabled: {
        backgroundColor: 'GREYSCALE.CONSTELLATION'
      }
    }
  }
}));
const primary = defineStyle({
  color: 'GREYSCALE.MOON',
  backgroundColor: 'CARNABY.regular',
  _hover: {
    backgroundColor: 'CARNABY.dark'
  },
  _pressed: {
    backgroundColor: 'CARNABY.dark'
  }
});
const secondary = defineStyle({
  color: 'CARNABY.regular',
  backgroundColor: 'CARNABY.extraLight',
  borderColor: 'CARNABY.regular',
  border: '1px',
  _hover: {
    backgroundColor: 'CARNABY.light'
  },
  _pressed: {
    backgroundColor: 'CARNABY.light'
  }
});
const tertiary = defineStyle({
  color: 'CARNABY.regular',
  backgroundColor: 'GREYSCALE.CONSTELLATION',
  _hover: {
    backgroundColor: 'GREYSCALE.SATELLITE'
  },
  _pressed: {
    backgroundColor: 'GREYSCALE.SATELLITE'
  }
});
const danger = defineStyle({
  color: 'GREYSCALE.MOON',
  backgroundColor: 'CHERRY_BLOSSOM.dark',
  _hover: {
    backgroundColor: 'CHERRY_BLOSSOM.regular'
  },
  _pressed: {
    backgroundColor: 'CHERRY_BLOSSOM.dark'
  }
});
const ghost = defineStyle({
  color: 'GREYSCALE.CELESTIAL'
});
const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: {
    xs: {
      minHeight: '26px',
      paddingX: 3
    },
    sm: {
      minHeight: '32px',
      paddingX: 4
    },
    md: {
      minHeight: '40px',
      paddingX: 5
    },
    xl: {
      fontSize: 'lg',
      px: '6',
      h: '14'
    }
  },
  variants: {
    primary,
    secondary,
    tertiary,
    danger,
    ghost
  }
});
export default buttonTheme;