import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { createTextFieldBorderColors } from '../../constants';
import { variantFilled, variantOutlined } from '../../focusShim';
const {
  definePartsStyle,
  defineMultiStyleConfig
} = createMultiStyleConfigHelpers(inputAnatomy.keys);
export const unifiedInputStyle = Object.assign({
  fontSize: 'sm',
  backgroundColor: 'GREYSCALE.SUPERNOVA'
}, variantFilled());
export const whiteBackgroundInputStyle = Object.assign({
  fontSize: 'sm',
  backgroundColor: '#FFF'
}, variantFilled());
const baseStyle = definePartsStyle(() => {
  return {
    field: Object.assign({}, unifiedInputStyle, {
      border: 'none',
      _empty: unifiedInputStyle,
      _focus: unifiedInputStyle,
      _focusWithin: unifiedInputStyle,
      _visited: unifiedInputStyle,
      _blur: unifiedInputStyle,
      _invalid: Object.assign({}, unifiedInputStyle, {
        border: '1px solid'
      })
    })
  };
});
const xl = defineStyle({
  fontSize: 'lg',
  px: '4',
  h: '14',
  borderRadius: 'lg'
});
const sizes = {
  xl: definePartsStyle({
    field: xl,
    addon: xl
  })
};
const highlighted = definePartsStyle({
  field: Object.assign({
    fontSize: 'md',
    backgroundColor: '#FFF',
    border: '1px solid'
  }, createTextFieldBorderColors())
});
const pill = definePartsStyle({
  field: Object.assign({
    borderRadius: 'full',
    border: '1px solid'
  }, variantOutlined())
});
const outline = definePartsStyle(() => {
  return {
    addon: {
      px: 2
    },
    field: {
      _focus: Object.assign({}, createTextFieldBorderColors()),
      _focusVisible: Object.assign({
        shadow: 'none'
      }, createTextFieldBorderColors())
    }
  };
});
const searchField = definePartsStyle(() => {
  return {
    field: Object.assign({}, whiteBackgroundInputStyle, {
      border: 'none',
      _empty: whiteBackgroundInputStyle,
      _focus: whiteBackgroundInputStyle,
      _focusWithin: whiteBackgroundInputStyle,
      _visited: whiteBackgroundInputStyle,
      _blur: whiteBackgroundInputStyle,
      _invalid: Object.assign({}, whiteBackgroundInputStyle, {
        border: '1px solid',
        borderColor: 'KYOTO.regular !important'
      })
    })
  };
});
const searchFieldGrey = Object.assign({}, baseStyle);
const simple = definePartsStyle(() => {
  return {
    field: {
      border: 0,
      backgroundColor: '#FFF!important',
      _focus: {
        border: 0,
        boxShadow: 'none!important',
        borderTopColor: '#FFF!important',
        backgroundColor: '#FFF!important'
      },
      _focusVisible: {
        border: 0,
        boxShadow: 'none!important',
        borderWidth: '0px!important',
        backgroundColor: '#FFF!important'
      },
      _focusWithin: {
        border: 0,
        boxShadow: 'none!important',
        borderWidth: '0px!important',
        backgroundColor: '#FFF!important'
      }
    }
  };
});
export default defineMultiStyleConfig({
  defaultProps: {
    // @ts-expect-error - errorBorderColor is a valid prop, I'm not sure why TS doesn't like me setting it.
    errorBorderColor: 'KYOTO.dark'
  },
  baseStyle,
  variants: {
    highlighted,
    pill,
    outline,
    simple,
    searchField,
    searchFieldGrey
  },
  sizes
});